import axios from 'axios'
import {Commit} from 'vuex'
import {URLS} from '@/common/urls-enum'
import {User} from '@/types/user'

export interface UsersState {
  operator: User
}

const state: UsersState = {
  operator: {} as User
}

const getters = {
  operator: (state: UsersState): User => state.operator
}

const actions = {
  async getUser ({commit}: { commit: Commit }, username: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.USERS}${username}/`,
      responseType: 'json'
    });
  },
}

const mutations = {
  setOperator (state: UsersState, operator: User): void {
    state.operator = operator
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
