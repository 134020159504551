import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueGtag from 'vue-gtag-next'
import { User } from '@/types/user'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response) {
      if (error.response.status === 403) {
        router.push('/')
      } else if (error.response.status === 401) {
        localStorage.removeItem('auth')
        router.push('/login')
      }
      return Promise.reject(error)
    } else if (error.request) {
      console.log('error: ', error.request)
    } else {
      console.log('error: ', error.message);
    }
  }
)

// Google Analytics
function setUserId (): string {
  const operator = JSON.parse(localStorage.getItem('insylo_operator') || '{}') as User
  if (operator) {
    return operator.id
  }
  return ''
}

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GA_ID,
      params: {
        send_page_view: false,
        user_id: setUserId()
      }
    }
  }, router)
  .mount('#app')
