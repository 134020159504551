export default {
  measureUnits: {
    kgDay: 'kg/dia',
    tDay: 't/dia',
    day: 'dia | dies',
    full: 'Ple',
    empty: 'Buit'
  },
  login: {
    inputUsername: 'Nom d\'usuari',
    inputPassword: 'Paraula clau',
    buttonLogin: 'Inici de Sessió',
    inputUsernameValidation: 'Siusplau entri nom d\'usuari',
    inputPasswordValidation: 'Siusplau entri paraula clau'
  },
  menu: {
    farms: 'Granges',
    map: 'Mapa',
    scanQR: 'Escanejar QR',
    profile: 'Perfil',
    logOut: 'Sortir'
  },
  areas: {
    bin: 'sense silo | silo | silos'
  },
  areaDetails: {
    productRecipe: 'Producte',
    lastUpdate: 'Darrera actualizació',
    noChartData: 'No hi han suficients dades per mostrar la gràfica',
    noSensor: 'No es detecta sensor'
  },
  binDetails: {
    productRecipe: 'Producte',
    maxCapacity: 'Capacitat Màxima',
    fixedMaxCapacity: 'Capacitat Màxima Fixada',
    available: 'Disponible',
    deviceStatus: 'Estat de dispositiu',
    temperature: 'Temperatura',
    humidity: 'Humitat',
    battery: 'Bateria',
    lastCheck: 'Darrera lectura',
    notSendingData: 'Dispositiu no envia des de',
    noSensor: 'No es detecta sensor',
    lastCheckImages: 'Darrera captura',
    noRgbImage: 'Imatge no disponible',
    noDepthMapImage: 'Mesura de distància no disponible'
  }
}
