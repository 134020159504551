import axios from 'axios'
import { Commit } from 'vuex'
import { URLS } from '@/common/urls-enum'
import { FetchBinError, BinOperationStatus } from '@/types/bin'

export interface BinsState {
  fetchBinError: FetchBinError,
  binOperationStatuses: BinOperationStatus[]
}

const state: BinsState = {
  fetchBinError: {} as FetchBinError,
  binOperationStatuses: []
}

const getters = {
  fetchBinError: (state: BinsState): FetchBinError => state.fetchBinError,
  binOperationStatuses: (state: BinsState): BinOperationStatus[] => state.binOperationStatuses
}

const actions = {
  async getBin ({commit}: { commit: Commit }, uuid: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.BINS}${uuid}/`,
      responseType: 'json'
    });
  },
  async getBinDeviceForRecipe ({commit}: { commit: Commit }, uuid: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.BINS}${uuid}/device/`,
      responseType: 'json',
      params: { limit: 1, sort: -1, exist: 'recipeId' }
    });
  },
  async getBinDeviceLastReading ({commit}: { commit: Commit }, uuid: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.BINS}${uuid}/device/`,
      responseType: 'json',
      params: { limit: 1, sort: -1, exist: 'depthMapImage' }
    });
  },
  async getdeviceConfigTime ({commit}: { commit: Commit }, uuid: string): Promise<any> {
    try {
      return axios({
        method: 'get',
        url: `devices/${uuid}/timeConfig`,
        responseType: 'json'
      })
    } catch (error: any) {
      console.log('Error: ', error.response.data.msg)
    }
  },

  // statuses
  async getStatuses ({commit}: { commit: Commit }): Promise<any> {
    try {
      const response = await axios({
        method: 'get',
        url: `${URLS.STATUSES}/`,
        responseType: 'json'
      })
      const statuses = (response.data) ? response.data : []
      commit('setBinStatuses', statuses)
    } catch (error: any) {
      console.log('Error: ', error.response.data.msg)
    }
  }
}

const mutations = {
  setFetchBinError (state: BinsState, error: FetchBinError): void {
    state.fetchBinError = error
  },
  setBinStatuses (state: BinsState, binOperationStatuses: BinOperationStatus[]): void {
    state.binOperationStatuses = binOperationStatuses
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
