import axios from 'axios'
import { Commit } from 'vuex'
import {URLS} from '@/common/urls-enum'

const state = {}
const getters = {}
const actions = {
  async getRecipe ({commit}: { commit: Commit }, id: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.RECIPES}${id}/`,
      responseType: 'json'
    });
  },
}
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
