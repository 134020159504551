
import { defineComponent, PropType } from 'vue'
import { User } from '@/types/user'
import { Bin } from '@/types/bin'
import dateTimeFormatting from '@/common/mixins-date-time'
import calculations from '@/common/mixins-calculations'
import utilities from '@/common/mixins-utilities'
import BinColorConfig from '@/common/bin-color-config'

export default defineComponent ({
  name: 'AreasListBinItem',
  mixins: [
    dateTimeFormatting,
    calculations,
    utilities
  ],
  props: {
    bin: {
      type: Object as PropType<Bin>,
      required: true
    }
  },
  data () {
    return {
      operator: JSON.parse(localStorage.getItem('insylo_operator') || '{}') as User
    }
  },
  methods: {
    // shortened methods called from mixins
    isBinFullOrEmpty (): string {
      return this.binFullEmpty(
        this.binCurrentVolume(this.bin.data.measurements),
        this.bin.specs.estimatedVolume,
        this.bin.specs.fullThresholdPercent,
        this.bin.specs.emptyThresholdPercent
      )
    },
    binTextColorCss (): string {
      return BinColorConfig(
        this.bin,
        // this.binVolumeRemainingPercentage(this.binCurrentVolume(this.bin.data.measurements), this.bin.specs.estimatedVolume),
        this.binCapacityRemainingPercentage(this.bin.specs.estimatedVolume, this.binRecipe(this.bin.data.stock).density, this.kilosToTonnes(this.binCurrentWeight(this.bin.data.stock)), this.bin.weight.fixedMaxCapacity),

        'binTextColor'
      )
    },

    isOperationGood () {
      if (this.bin.data.operations && this.bin.data.operations.status && this.bin.data.operations.status.status) {
        const status = this.bin.data.operations.status.status
        return (status !== '0_ALERT_DETECTED')
      }
      return true
    },
    // if device is not sending data for more then 24h
    deviceNotSendingData (): boolean {
      if (this.bin.data.stock && this.bin.data.stock.last_update) {
        const sinceLastReading = (Date.now() - Date.parse(this.timeInOperatorTimezone(this.bin.data.stock.last_update))) / 3600000
        if (sinceLastReading > 24) return true
      }
      return false
    },
    // if device is not estimating values for more then 25h
    deviceNotEstimatingValue () {
      if (this.deviceNotSendingData() && this.bin.meta && this.bin.meta.lastDataReceivedAt) {
        const sinceLastData = (Date.now() - Date.parse(this.timeInOperatorTimezone(this.bin.meta.lastDataReceivedAt))) / 3600000
        if (sinceLastData > 25) return true
      }
      return false
    }
  }
})
