export const URLS = {
  MAP_LAYER_SATELLITE: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  MAP_LAYER_STREET: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  LOGIN: 'login/',
  DASHBOARD: 'client/dashboard/',
  USERS: 'accounts/users/',
  BINS: 'bins/',
  AREAS: 'areas/',
  RECIPES: 'recipes/',
  CLIENT_PERMISSION_CHECK: 'client/permission/check/',
  STATUSES: 'statuses'
}

export default Object.freeze(URLS)
