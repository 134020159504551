import { Bin } from '@/types/bin'

// question: can bin.data.stock_config.currentState be used for color?
function binIsYellow (bin: Bin, remainingVolumePercent: number): boolean {
  const binStockConfig = bin.data.stock_config
  return (
    (
      // if sates colors are based on estimated remaining days
      binStockConfig.stateType.eta === 'yes' &&
      bin.data.stock?.eta <= binStockConfig.states.ORANGE.remainingDays &&
      bin.data.stock?.eta > binStockConfig.states.RED.remainingDays
    ) || (
      // if sates colors are based on remaining volume percentage
      binStockConfig.stateType.percent === 'yes' &&
      remainingVolumePercent <= binStockConfig.states.ORANGE.percentage &&
      remainingVolumePercent > binStockConfig.states.RED.percentage
    )
  )
}

function binIsRed (bin: Bin, remainingVolumePercent: number): boolean {
  const binStockConfig = bin.data.stock_config
  return (
    (
      // if sates colors are based on estimated remaining days
      binStockConfig.stateType.eta === 'yes' &&
      bin.data.stock?.eta <= binStockConfig.states.RED.remainingDays &&
      bin.data.stock?.eta >= 0  // negative possible when problem with data
    ) || (
      // if sates colors are based on remaining volume percentage
      binStockConfig.stateType.percent === 'yes' &&
      remainingVolumePercent <= binStockConfig.states.RED.percentage
    )
  )
}

function setColor (bin: Bin, remainingVolumePercent: number): string {
  if (binIsRed(bin, remainingVolumePercent)) {
    if (bin.type === 'W') return 'green'
    return 'red'
  } else if (binIsYellow(bin, remainingVolumePercent)) {
    return 'yellow'
  } else {
    if (bin.type === 'W') return 'red'
    return 'green'
  }
}

function roundedVolumePercent (volumePercent: number): string {
  // needs to return two digits to be used in image name url
  if (!volumePercent || !isFinite(volumePercent)) return '00'
  const calculatedPercent = Math.round(volumePercent / 10).toString()
  if (calculatedPercent === '10') return calculatedPercent
  return ('0' + calculatedPercent)
}

export default (bin: Bin, remainingVolumePercent: number, useCase: string): string => {
  if (useCase === 'binTextColor') {
    // returns css class name
    return 'textColor-' + setColor(bin, remainingVolumePercent)
  }

  if (useCase === 'binImg') {
    // returns image url
    if (bin.type === 'W') {
      return require(
        '@/assets/images/bin-levels/bin-' +
        setColor(bin, remainingVolumePercent) + '.png'
      )
    }
    return require(
      '@/assets/images/silo-levels/silo-' +
      roundedVolumePercent(remainingVolumePercent) +
      '0-' + setColor(bin, remainingVolumePercent) + '.svg'
    )
  }

  return ''
}
