
import {defineComponent, PropType} from 'vue'

export default defineComponent({
  name: 'Notifications',
  props: {
    message: {
      type: String as PropType<string>,
      required: true
    },
    type: {
      type: String as PropType<string>,
      required: true
    }
  },
})
