import axios from 'axios'
import { Commit } from 'vuex'
import { URLS } from '@/common/urls-enum'
import { AreaWithBins } from '@/types/area'

export interface AreasState {
  dashboardAreas: AreaWithBins[]
}

const state: AreasState = {
  dashboardAreas: []
}
const getters = {
  dashboardAreas: (state: AreasState): AreaWithBins[] => state.dashboardAreas,
}
const actions = {
  async getDashboardAreas({commit}: { commit: Commit }): Promise<any> {
    if (state.dashboardAreas.length) return
    try {
      const response = await axios({
        method: 'get',
        url: `${URLS.DASHBOARD}`,
        responseType: 'json'
      })
      const areas = (response.data) ? response.data : []
      commit('setDashboardAreas', areas)
    } catch (error) {
      console.log('Error: ', error.response.data.msg)
    }
  },
  // single area with list of bins
  async getDashboardArea ({commit}: { commit: Commit }, areaId: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.DASHBOARD}${areaId}/`,
      responseType: 'json'
    })
  },
  async getArea ({commit}: { commit: Commit }, areaId: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.AREAS}${areaId}/`,
      responseType: 'json'
    })
  }
}
const mutations = {
  setDashboardAreas (state: AreasState, areas: AreaWithBins[]): void {
    state.dashboardAreas = areas
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
