import axios from 'axios'
import { Commit } from 'vuex'
import { URLS } from '@/common/urls-enum'

const state = {}
const getters = {}
const actions = {
  async checkUserPermission ({commit}: { commit: Commit }, tags: string): Promise<any> {
    return axios({
      method: 'get',
      url: `${URLS.CLIENT_PERMISSION_CHECK}`,
      responseType: 'json',
      params: {
        tags: tags
      }
    })
  },
}
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
