import { DateTime } from 'luxon'
import { User } from '@/types/user'

const operator: User = JSON.parse(localStorage.getItem('insylo_operator') || '{}')

interface DateTimeFormatList {
  [key: string]: DateTimeFormat;
}

interface DateTimeFormat {
  [key: string]: string;
}

const dateFormats: DateTimeFormatList = {
  europe: {
    // Luxon formatting tokens https://github.com/moment/luxon/blob/master/docs/formatting.md
    dateTime: 'dd/LL/yyyy HH:mm',
    timeDate: 'HH:mm dd/LL/yyyy',
    time: 'HH:mm',
    fullDate: 'dd/LL/yyyy',
    date: 'dd LLL'
  }
}

export default {
  methods: {
    timeInOperatorTimezone(dateTime: string, format?: string): string {
      /*
        Get Date and Time in the Timezone of the user:
        from UTC: 2019-09-30T09:04:41.835Z
        to for ex:
          England (+1h)   : 2019-09-30T10:04:41.835Z
          Catalonia (+2h) : 2019-09-30T11:04:41.835Z
       */
      const timezone = this.operatorTimezone()
      if (format) {
        const localFormat = dateFormats.europe[format]
        return DateTime.fromISO(dateTime, { zone: timezone }).toFormat(localFormat)
      }
      return DateTime.fromISO(dateTime, { zone: timezone }).toString()
    },
    timezoneDateTimeFormat (timezone: string): DateTimeFormat {
      /*
        get the dateTime format according to the Timezone convention:
        https://en.wikipedia.org/wiki/Date_format_by_country
      */
      return dateFormats[timezone]
    },
    operatorTimezone () {
      let timezone = ''
      if (operator.meta && operator.meta.timezone) {
        timezone = operator.meta.timezone
      } else {
        // operator's device timezone
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      if (!timezone) timezone = 'Europe/Madrid'
      return timezone
    },
    showHoursInOperatorTimeZone (timeStart: number, timeEnd: number): [number, number] {
      let startLocal: number
      let endLocal: number
      if (timeEnd !== 24 && timeStart !== 0) {
        const date = new Date()
        const hoursDifference = date.getHours() - date.getUTCHours()
        startLocal = timeStart + hoursDifference
        endLocal = timeEnd + hoursDifference
      } else {
        return [timeStart, timeEnd]
      }
      return [startLocal, endLocal]
    }
  }
}
