
import { defineComponent } from 'vue'
import Navigation from '@/components/shared/Navigation.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    Navigation
  }
})
