
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Header',
  props: {
    pageType: {
      type: String as PropType<string>,
      required: false,
      default: 'main'
    },
    title: {
      type: String as PropType<string>,
      required: false
    },
    label: {
      type: String as PropType<string>,
      required: false
    }
  },
  data () {
    return {
      menuVisible: false
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    logOut () {
      localStorage.clear()
      this.$router.go(0)  // refresh the page to clear vuex store (maybe to find better solution)
      this.$router.push('/login')
    },
    changeMenuVisibility () {
      this.menuVisible = !this.menuVisible
    }
  }
});
