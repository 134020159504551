
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import axios from 'axios'
import { User } from '@/types/user'
import { PermissionAllowance } from '@/types/permissions'
import Loading from '@/components/shared/Loading.vue'
import Notifications from '@/components/shared/Notifications.vue'
import { MESSAGES } from '@/common/messages'

export default defineComponent({
  name: 'Login',
  components: {
    Loading,
    Notifications
  },
  data () {
    return {
      loginUser: {
        username: '',
        password: ''
      } as {
        username: string;
        password: string;
      },
      operator: {} as User,
      subuserPermissions: [] as PermissionAllowance[],

      // list of tags on which we test access in the app for subusers
      permissionTags: 'viewRecipes',

      // validation
      usernameRequired: true,
      passwordRequired: true,

      // notification
      notificationVisible: false,
      notificationType: '',
      notificationMessage: '',

      // utility
      loading: false
    }
  },
  methods: {
    ...mapActions(['logIn', 'getUser', 'checkUserPermission']),

    submitLogin () {
      if (this.validateLoginForm()) {
        this.loading = true
        this.logIn(this.loginUser).then(res => {
          this.storeAuthenticationData(res.data.token)
          this.fetchOperatorData()
        }).catch(error => {
          if (error.response && error.response.status === 401) {
            // unauthenticated
            this.notificationOpened('negative', MESSAGES.login.error)
          } else if (error.response && error.response.status === 403) {
            // forbidden - probably wrong username or password
            this.notificationOpened('negative', error.response.data.msg)
          } else if (!error.response) {
            this.notificationOpened('negative', 'Error: Network Error')
          } else {
            console.log('login error: ', error);
          }
        }).finally(() => this.loading = false)
      }
    },
    storeAuthenticationData (token: string) {
      const authValue: {username: string; token: string} = {
        username: this.loginUser.username,
        token: token
      }
      localStorage.setItem('auth', JSON.stringify(authValue))
      axios.defaults.headers.common = {
        Authorization: 'Bearer ' + token
      }
    },
    async fetchOperatorData () {
      await this.getUser(this.loginUser.username).then(user => {
        this.operator = user.data
      })
      if (this.setUserType(this.operator) === 'Subuser') {
        await this.checkUserPermission(this.permissionTags).then(result => {
          this.subuserPermissions = result.data
        })
      }
      this.setOperatorStorage()
      await this.$router.push('/')
    },
    setOperatorStorage () {
      localStorage.setItem('insylo_operator', JSON.stringify(this.operator))
      localStorage.setItem('insylo_operatorType', this.setUserType(this.operator))
      if (this.setUserType(this.operator) === 'Subuser') {
        localStorage.setItem('insylo_operatorSubuserPermissions', JSON.stringify(this.setSubuserTruePermissions()))
      }
    },
    setUserType (user: User): string {
      if (user.staff === 1) {
        return 'Staff'
      } else if (user.staff === 0 && user.ownerId) {
        return 'Subuser'
      } else {
        return 'Owner'  // also called Client
      }
    },
    setSubuserTruePermissions (): string[] {
      const permissionsArray: string[] = []
      for (const permission of this.subuserPermissions) {
        if (permission.allowed) {
          permissionsArray.push(permission.permissionTag)
        }
      }
      return permissionsArray
    },

    validateLoginForm (): boolean {
      this.validateUsername()
      this.validatePassword()
      return (this.usernameRequired && this.passwordRequired)
    },
    validateUsername () {
      this.usernameRequired = this.validityRuleRequired(this.loginUser.username)
    },
    validatePassword () {
      this.passwordRequired = this.validityRuleRequired(this.loginUser.password)
    },

    // validation rules
    validityRuleRequired (inputValue: string): boolean {
      return Boolean(inputValue)
    },

    // notification setup
    notificationOpened (notifType: string, notifMessage: string) {
      this.notificationType = notifType
      this.notificationMessage = notifMessage
      this.notificationVisible = true
      this.notificationCloseTimer()
    },
    notificationReset () {
      this.notificationType = ''
      this.notificationMessage = ''
      this.notificationVisible = false
    },
    notificationCloseTimer () {
      setTimeout(() => {
        this.notificationReset()
      }, 6000);
    }
  }
});
