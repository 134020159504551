
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Bin } from '@/types/bin'
import Loading from '@/components/shared/Loading.vue'
import HeaderBar from '@/components/shared/Header.vue'
import AreasListBinItem from '@/components/areas/AreasListBinItem.vue'

export default defineComponent({
  name: 'Farms',
  components: {
    Loading,
    HeaderBar,
    BinItem: AreasListBinItem
  },
  data () {
    return {
      binsListRefs: [] as Array<HTMLElement>,

      // utilities
      listReady: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['dashboardAreas']),

    accordionAnimationClass (): string {
      if (this.listReady) return 'accordion-animated'
      return ''
    }
  },
  created () {
    this.fetchDashboardAreas()
  },
  mounted () {
    if (this.binsListRefs.length) this.setBinsListHeight()
  },
  beforeUpdate () {
    this.binsListRefs = []
  },
  updated () {
    this.$nextTick(() => {
      if (!this.listReady) this.setBinsListHeight()
    })
  },
  methods: {
    ...mapActions(['getDashboardAreas']),

    async fetchDashboardAreas () {
      this.loading = true
      await this.getDashboardAreas()
      this.loading = false
    },

    areaBinsCountNumber (bins: Array<Bin>): number {
      if (bins) return bins.length
      return 0
    },

    // accordion
    setBinsListRefs (element: HTMLElement) {
      if (element) this.binsListRefs.push(element)
    },
    setBinsListHeight () {
      for (const binList of this.binsListRefs) {
        binList.style.maxHeight = binList.scrollHeight + "px"
      }
      this.listReady = true
    },
    runBinsListAccordion (event: Event) {
      const accordionTrigger: HTMLElement = event.currentTarget as HTMLElement
      const triggerParent: HTMLElement = accordionTrigger.parentElement as HTMLElement
      const accordionTarget: HTMLElement = triggerParent.nextElementSibling as HTMLElement
      if (accordionTarget.style.maxHeight) {
        accordionTarget.style.maxHeight = ''
      } else {
        accordionTarget.style.maxHeight = accordionTarget.scrollHeight + "px"
      }
      accordionTrigger.classList.toggle('closed')
    }
  }
})
