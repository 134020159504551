import { createStore } from 'vuex'
import auth from './modules/auth'
import users from './modules/users'
import areas from './modules/areas'
import bins from './modules/bins'
import recipes from './modules/recipes'
import permissions from './modules/permissions'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    users,
    areas,
    bins,
    recipes,
    permissions
  }
})
