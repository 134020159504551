export default {
  measureUnits: {
    kgDay: 'kg/dia',
    tDay: 't/dia',
    day: 'dia | dias',
    full: 'Lleno',
    empty: 'Vacio'
  },
  login: {
    inputUsername: 'Nombre de usuario',
    inputPassword: 'Palabra clave',
    buttonLogin: 'Inicio Sesión',
    inputUsernameValidation: 'Por favor entre nombre de usuario',
    inputPasswordValidation: 'Por favor entre palabla clave'
  },
  menu: {
    farms: 'Granjas',
    map: 'Mapa',
    scanQR: 'Escanear QR',
    profile: 'Perfil',
    logOut: 'Salir'
  },
  areas: {
    bin: 'sin silo | silo | silos'
  },
  areaDetails: {
    productRecipe: 'Producto',
    lastUpdate: 'Última actualización',
    noChartData: 'No hay suficentes datos para mostrar el gráfico',
    noSensor: 'No se detecta sensor'
  },
  binDetails: {
    productRecipe: 'Producto',
    maxCapacity: 'Capacidad Máxima',
    fixedMaxCapacity: 'Capacidad Máxima Fijada',
    available: 'Disponible',
    deviceStatus: 'Estado del dispositivo',
    temperature: 'Temperatura',
    humidity: 'Humedad',
    battery: 'Batería',
    lastCheck: 'Última lectura',
    notSendingData: 'Dispositivo no envia desde',
    noSensor: 'No se detecta sensor',
    lastCheckImages: 'Última captura',
    noRgbImage: 'Imagen no disponible',
    noDepthMapImage: 'Medida de distancia no disponible'
  }
}
