import axios from 'axios'
import { Commit } from 'vuex'

const state = {
}
const getters = {
}
const actions = {
  async logIn({ commit }: { commit: Commit }, user: {username: string; password: string}): Promise<any> {
    return axios({
      method: 'post',
      url: 'login/',
      responseType: 'json',
      data: {
        username: user.username,
        password: user.password
      }
    })
  }
}
const mutations = {
}
export default {
  state,
  getters,
  actions,
  mutations
}
