export default {
  methods: {
    kilosToTonnes (weight: number): number {
      // rounded on two decimals
      return (Math.round(weight / 10) / 100)
    },
    NaNToString (number: number): number | string {
      if (number !== 0 && !number) return '-'
      return number
    }
  }
}
