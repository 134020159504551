// i18n translations, docs on https://vue-i18n.intlify.dev/
import { createI18n } from 'vue-i18n'
import en from './en/'
import es from './es/'
import ca from './ca/'

const messages = {
  'en': en,
  'es': es,
  'ca': ca
}

function setBrowserLanguage (): string {
  let browserLanguage = 'en'
  if (navigator.languages || navigator.language) browserLanguage = navigator.languages[0] || navigator.language
  return browserLanguage
}

const i18n = createI18n({
  locale: setBrowserLanguage(),
  fallbackLocale: {
    'ca': ['es'],
    'default': ['en']
  },
  messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true
})

export default i18n

// for cases when $t is not available
export const translate = (key: string): string => {
  if (!key) {
    return ''
  }
  return i18n.global.t(key)
}
