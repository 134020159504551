import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import axios from 'axios'
import DefaultLayout from '@/layouts/Default.vue'
import Login from '@/views/Login.vue'
import Areas from '@/views/Areas.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      needAuthentication: false
    }
  },
  {
    path: '/',
    redirect: '/farms',
    component: DefaultLayout,
    children: [
      {
        path: '/farms',
        name: 'Areas',
        component: Areas,
        meta: {
          needAuthentication: true
        }
      },
      {
        path: '/map',
        name: 'Map',
        component: () => import('../views/Map.vue'),
        meta: {
          needAuthentication: true
        }
      },
      {
        path: '/farm/:farmId',
        name: 'Area Details',
        component: () => import('../views/AreaDetails.vue'),
        meta: {
          needAuthentication: true
        },
        props: true
      },
      {
        path: '/bin/:uuId',
        name: 'Bin Details',
        component: () => import('../views/BinDetails.vue'),
        meta: {
          needAuthentication: true
        },
        props: true
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue'),
        meta: {
          needAuthentication: true
        }
      },
      {
        path: '/scan-qr',
        name: 'Scan QR',
        component: () => import('../views/ScanQR.vue'),
        meta: {
          needAuthentication: true
        }
      },
      {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error.vue'),
        meta: {
          needAuthentication: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // if authentication data exists set axios header
  const authentication: string | null = localStorage.getItem('auth')
  if (authentication) {
    axios.defaults.headers.common = {
      Authorization: 'Bearer ' + JSON.parse(authentication).token
    }
  }

  if (to.matched.some((record) => record.meta.needAuthentication)) {
    if (authentication) return next()
    next('/login')
  } else if (!to.matched.length) {
    next('/')
  } else {
    next()
  }
})

export default router
