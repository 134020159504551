import {BinDataStock, BinDataMeasurements, Recipe} from '@/types/bin'

export default {
  methods: {
    // Bin's total capacity in tonnes, rounded on two decimals
    binCapacityTotal (estimatedVolume: number, density: number, fixedMaxCapacity?: number): number {
      if (fixedMaxCapacity === undefined) {
        return Math.round((estimatedVolume * density / 1000) * 100) / 100
      } else {
        return Math.round((fixedMaxCapacity) * 100) / 100
      }
    },
    // Bin's remaining (available) capacity in tonnes, rounded on two decimals
    binCapacityRemaining (estimatedVolume: number, density: number, weight: number, fixedMaxCapacity?: number): number {
      if (fixedMaxCapacity === undefined) { // calculate the total capacity from estimated volume and density
        return Math.round(((estimatedVolume * density / 1000) - weight) * 100) / 100
      } else { // Return the difference between fixedMaxCapacity and current weight
        return Math.round((fixedMaxCapacity - weight) * 100) / 100
      }
    },
    // Bin's remaining (available) volume in percentages, rounded on two decimals
    binVolumeRemainingPercentage (volume: number, estimatedVolume: number): number {
      // round input on two decimals for consistency (API is not)
      const currentVolume = Math.round(volume * 100) / 100
      return Math.round((currentVolume / estimatedVolume) * 10000) / 100
    },
    // Bin's remaining (available) capacity in percentages, rounded on two decimals
    binCapacityRemainingPercentage (estimatedVolume: number, density: number, weight: number, fixedMaxCapacity?: number): number {
      if (fixedMaxCapacity === undefined) { // calculate the total capacity from estimated volume and density
        return Math.round((weight / (estimatedVolume * density / 1000) * 100) * 100) / 100
      } else { // Return the capacity percentatge from fixedMaxCapacity and current weight
        return Math.round((weight / fixedMaxCapacity * 100) * 100) / 100
      }
    },

    binCurrentVolume (binDataMeasurements: BinDataMeasurements): number {
      if (binDataMeasurements && binDataMeasurements.currentVolume) {
        if (binDataMeasurements.currentVolume < 0) return 0
        return binDataMeasurements.currentVolume
      }
      return 0
    },
    binCurrentWeight (binDataStock: BinDataStock): number {
      if (binDataStock && binDataStock.current_stock) {
        return binDataStock.current_stock
      }
      return 0
    },
    binRecipe (binDataStock: BinDataStock): Recipe {
      if (binDataStock && binDataStock.recipe && binDataStock.recipe.recipe) {
        return binDataStock.recipe.recipe
      }
      return {} as Recipe
    },

    useBinThresholds (operatorUseBinThresholds: boolean, binUseThresholdPercent: string): boolean {
      // binUseThresholdPercent can be 'yes', 'no' or ''
      return Boolean(
        operatorUseBinThresholds &&
        binUseThresholdPercent &&
        binUseThresholdPercent === 'yes'
      )
    },
    binFullEmpty (
      volume: number,
      estimatedVolume: number,
      fullThresholdPercent: number,
      emptyThresholdPercent: number
    ): string {
      if (this.binVolumeRemainingPercentage(volume, estimatedVolume) >= fullThresholdPercent) {
        return 'Full'
      } else if (this.binVolumeRemainingPercentage(volume, estimatedVolume) <= emptyThresholdPercent) {
        return 'Empty'
      }
      return ''
    }
  }
}
