export default {
  measureUnits: {
    kgDay: 'kg/day',
    tDay: 't/day',
    day: 'day | days',
    full: 'Full',
    empty: 'Empty'
  },
  login: {
    inputUsername: 'Username',
    inputPassword: 'Password',
    buttonLogin: 'Login',
    inputUsernameValidation: 'Please type username',
    inputPasswordValidation: 'Please type password'
  },
  menu: {
    farms: 'Farms',
    map: 'Map',
    scanQR: 'Scan QR',
    profile: 'Profile',
    logOut: 'Log out'
  },
  areas: {
    bin: 'no bins | bin | bins'
  },
  areaDetails: {
    productRecipe: 'Product recipe',
    lastUpdate: 'Last update',
    noChartData: 'Not enough data to display chart',
    noSensor: 'No sensor detected'
  },
  binDetails: {
    productRecipe: 'Product recipe',
    maxCapacity: 'Max capacity',
    fixedMaxCapacity: 'Fixed Max capacity',
    available: 'Available',
    deviceStatus: 'Device status',
    temperature: 'Temperature',
    humidity: 'Humidity',
    battery: 'Battery',
    lastCheck: 'Last check',
    notSendingData: 'Not sending data since',
    noSensor: 'No sensor detected',
    lastCheckImages: 'Last check images',
    noRgbImage: 'No RGB image available',
    noDepthMapImage: 'No depth map image available'
  }
}
